/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
@import 'animations';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  color: #333333;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  background-color: #fff;
  color: #333333;
  overflow-x: hidden;
  // background: #0d153a url('/images/bg.jpg') no-repeat right center / cover;
}
.wrap {
  padding: 30px 0;
  overflow-x: clip;
}
.container-fluid {
  &.but-fixed {
    max-width: 1356px;
  }
  &.wider {
    max-width: 1800px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.border-transparent {
  border-color: #ffffff9c !important;
}
.fw-strong {
  font-weight: 600 !important;
}
.parallax-overlay {
  height: calc(100% + 30px);
  top: -30px;
}
.zindex {
  z-index: 100;
}

/* --------- asc styles --------- */
#header-wrap {
  background-color: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  z-index: 999;
}
#header {
  // background: rgba(255, 255, 255, 0.8);
  a.logo {
    border: 0;
    width: 100%;
    max-width: 170px;
    min-width: 170px;
    img {
      width: auto;
      height: auto;
    }
  }
  #support {
    p {
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      padding: 0 12px 2px 12px;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    .phone a {
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e829';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
  #search {
    position: absolute;
    right: -115px;
    top: 48px;
    display: none;
    input[type='search'] {
      width: 250px;
      transition: all 0.5s cubic-bezier(0, 0.7, 0, 1);
      padding-left: 35px;
      background-color: #f4f4f4;
      background-position: 9px;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
      &::placeholder {
        color: #999 !important;
      }
    }
  }
}
.addsearch {
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'%3E%3Cg fill='none' stroke='gray' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2'%3E%3Cpath d='M10 3a7 7 0 107 7 7 7 0 00-7-7zM21 21l-6-6' vector-effect='non-scaling-stroke'%3E%3C/path%3E%3C/g%3E%3C/svg%3E")
    no-repeat 0px center;
  &.form-control {
    background-color: #f2f2f2;
  }
  &.main {
    background-position: 9px;
    padding-left: 35px;
  }
  &.nav-search-icon {
    height: 37px;
    width: 20px;
    cursor: pointer;
  }
}
.contact-icons {
  .phone {
    &:before {
      margin-right: 5px;
      content: '\e829';
      color: inherit;
      font-family: 'icons';
      font-size: 105%;
    }
  }
  .email {
    &:before {
      margin-right: 5px;
      content: '\e831';
      color: inherit;
      font-family: 'icons';
      font-size: 105%;
      position: relative;
      top: 2px;
    }
  }
}
#footer-wrap {
  background-color: $theme-primary;
}
.footer {
  width: 100%;
  background-color: transparent;
  color: #fff;
  // border-top: 1px solid #e4e4e4;
  font-size: 1rem;
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: inherit;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
  #trustwaveSealImage {
    margin: 0 10px 30px 0;
  }
  ul.links {
    padding-left: 0;
    list-style: none;
    li {
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }
  }
  a.social {
    height: 40px;
    width: 40px;
    margin: 0 5px;
    color: $theme-primary !important;
    background-color: #fff;
    border: 2px solid #fff;
    font-size: 19px;
    span {
      position: relative;
      top: -2px;
    }
  }
  a.social [class^='icon-']:before {
    line-height: 41px;
  }
  a.social:hover,
  a.social:active {
    font-size: 21px;
    background: $theme-primary;
    color: #fff !important;
  }
}

/* --------------------------------------------------------------
    Patterned BGs
-------------------------------------------------------------- */
.bg-slash-outline {
  background-image: url(/images/slash-outline.png);
  background-repeat: repeat;
  background-position: center center;
}
.bg-squares {
  background-image: url(/images/bg-squares-1.png);
  background-color: #fafafa;
  background-repeat: no-repeat;
  background-position: 100% 50%;
  &.one {
    background-image: url(/images/bg-squares-1.png);
  }
  &.two {
    background-image: url(/images/bg-squares-2.png);
  }
  &.three {
    background-image: url(/images/bg-squares-3.png);
  }
  &.four {
    background-image: url(/images/bg-squares-4.png);
  }
}
.border-light-blue {
  border-color: #f0f7fb !important;
}
.gradient-bg {
  background: linear-gradient(56deg, #293238, #47565d 20%, #37444b 78%, #283133);
  p {
    text-shadow: 1px 1px 15px #4e4e4ee6;
  }
}
.zoom-container {
  img.zoom {
    transition: transform 0.2s;
  }
  &:hover {
    img.zoom {
      transform: scale(1.05);
    }
  }
}
.side-socials {
  a.social {
    height: 40px;
    width: 40px;
    margin: 0 5px;
    color: #fff;
    background-color: $theme-primary-alt2;
    border: 2px solid $theme-primary-alt2;
    font-size: 19px;
    span {
      position: relative;
      top: -2px;
    }
  }
  a.social [class^='icon-']:before {
    line-height: 41px;
  }
  a.social:hover,
  a.social:active {
    color: $theme-primary-alt2 !important;
    background: #fff;
  }
}
.featured-training {
  svg path {
    fill: #bac8db;
    transition: 0.5s;
  }
  &:hover {
    svg path {
      fill: $theme-primary;
    }
  }
}
#icon {
  min-width: 120px;
}
#banner-wrap {
  z-index: 1;
  &.home img {
    width: 300px;
  }
}
#reviews-carousel {
  svg {
    position: absolute;
    left: 28px;
    top: -60px;
  }
  .tns-controls button {
    position: absolute;
    top: calc(50% - 20px);
    background: #e9e9e9;
    border: 3px solid #fff;
    color: #999;
    transition: 0.3s;
    line-height: 40px;
    &:hover {
      background: $theme-primary;
      color: #fff;
    }
    &[data-controls='prev'] {
      left: -30px;
    }
    &[data-controls='next'] {
      right: -30px;
    }
  }
  .tns-nav {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -50px;
    button {
      transition: 0.3s;
      &.tns-nav-active {
        transform: scale(1.6);
      }
    }
  }
}
.learn-more-arrow {
  color: $theme-secondary;
  &:before {
    margin-right: 5px;
    content: '\e824';
    font-family: 'icons';
    font-weight: normal;
  }
}
.rectangle-formation-one {
  .grey-rectangle {
    right: 27px;
    top: 1%;
  }
  .red-rectangle {
    right: 145px;
    top: 40%;
  }
  .blue-rectangle {
    right: 40px;
    top: 106px;
  }
  .blue-grey-rectangle {
    right: 160px;
    top: 5%;
  }
}
.rectangle-formation-two {
  .grey-rectangle {
    right: 27px;
    top: 1%;
  }
  .red-rectangle {
    right: 40px;
    top: 96px;
  }
  .blue-rectangle {
    right: 125px;
    top: 5%;
  }
  .blue-grey-rectangle {
    right: 173px;
    top: 105px;
  }
}
.grey-rectangle {
  width: 120px;
  height: 120px;
  background-color: #f5f5f5;
  border-radius: 20px;
  &.dark {
    background-color: #ebebeb;
  }
  &.small {
    width: 80px;
    height: 80px;
  }
  &.right {
    right: 0px;
    top: 10%;
  }
  &.left {
    left: -140px;
    top: 10%;
  }
  &.mobile {
    &.left {
      left: -25px;
      top: 18px;
    }
    &.right {
      right: -16px;
      top: 30px;
    }
  }
}

.red-rectangle {
  width: 170px;
  height: 170px;
  background-color: #de272894;
  border-radius: 20px;
  &.small {
    width: 100px;
    height: 100px;
  }
  &.light {
    background-color: #f1babb;
  }
  &.right {
    right: -100px;
    top: 40%;
  }
  &.left {
    left: -220px;
    top: 50%;
  }
  &.mobile {
    &.left {
      left: -105px;
      top: 88px;
    }
    &.right {
      right: -114px;
      top: 70px;
    }
  }
}
.blue-rectangle {
  width: 70px;
  height: 70px;
  background-color: #cce1ff;
  border-radius: 15px;
}
.blue-grey-rectangle {
  width: 50px;
  height: 50px;
  background-color: #dde3e9;
  border-radius: 10px;
}
.card {
  border: 1px solid #a1c4e5;
}
.subheading-light {
  color: $cta-dark;
}
.roboto {
  font-family: 'Roboto Slab', var(--font-family);
}
/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .bg-squares {
    background-position: 50% 50%;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  // this adds an extra padding to fluid containers
  [class*='container-'] {
    padding-right: var(--bs-gutter-x, 1.5rem);
    padding-left: var(--bs-gutter-x, 1.5rem);
  }
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header-wrap {
    border-top: 3px solid $theme-primary;
  }
  .bg-squares {
    background-position: 60% 50%;
  }
  #banner-wrap.home {
    img {
      width: initial;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  // this reverts the extra padding added to fluid containers
  [class*='container-'] {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
  }
  #reviews-carousel {
    svg {
      left: 75px;
    }
  }
  .bg-squares {
    background-position: 100% 50%;
    right: -270px;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #banner {
    h1 {
      font-size: 2.9rem;
      line-height: 1.2em;
    }
  }
  .bg-squares {
    max-width: 1356px;
    right: -60px;
  }
}
