@charset "UTF-8";
/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Custom Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Theme Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    CTA buttons
----------------------------------------------------------------------------- */
.text-theme-primary {
  color: #e32129 !important;
}

.text-theme-primary-alt {
  color: #0a2d50 !important;
}

.text-theme-primary-alt2 {
  color: #313131 !important;
}

.text-theme-secondary {
  color: #0d6efd !important;
}

.text-theme-secondary-alt {
  color: #f8fcff !important;
}

.text-theme-danger {
  color: #fb4f16 !important;
}

.border-theme-secondary {
  border-color: #0d6efd !important;
}

/* ----------------------------------------------------------------------------
    Backgrounds
----------------------------------------------------------------------------- */
.bg-theme-primary {
  background-color: #e32129 !important;
}

.bg-theme-primary-alt {
  background-color: #0a2d50 !important;
}

.bg-theme-primary-alt2 {
  background-color: #313131 !important;
}

.bg-theme-secondary {
  background-color: #0d6efd !important;
}

.bg-theme-secondary-alt {
  background-color: #f8fcff !important;
}

/* ----------------------------------------------------------------------------
    Font Icons
----------------------------------------------------------------------------- */
@font-face {
  font-family: "icons";
  src: url("../fonts/icons.eot?21099076");
  src: url("../fonts/icons.eot?21099076#iefix") format("embedded-opentype"), url("../fonts/icons.woff2?21099076") format("woff2"), url("../fonts/icons.woff?21099076") format("woff"), url("../fonts/icons.ttf?21099076") format("truetype"), url("../fonts/icons.svg?21099076#icon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
[class^=icon-]:before,
[class*=" icon-"]:before {
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  /*-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-dribbble:before {
  content: "\e801";
}

.icon-instagram:before {
  content: "\e802";
}

.icon-behance:before {
  content: "\e803";
}

.icon-linkedin:before {
  content: "\e804";
}

.icon-facebook:before {
  content: "\e805";
}

.icon-twitter:before {
  content: "\e806";
}

.icon-gplus:before {
  content: "\e807";
}

.icon-pinterest:before {
  content: "\e808";
}

.icon-tumblr:before {
  content: "\e809";
}

.icon-down:before {
  content: "\e810";
}

.icon-left:before {
  content: "\e811";
}

.icon-right:before {
  content: "\e812";
}

.icon-up:before {
  content: "\e813";
}

.icon-left-small:before {
  content: "\e814";
}

.icon-right-small:before {
  content: "\e815";
}

.icon-up-small:before {
  content: "\e816";
}

.icon-down-small:before {
  content: "\e817";
}

.icon-down-thin:before {
  content: "\e818";
}

.icon-left-thin:before {
  content: "\e819";
}

.icon-right-thin:before {
  content: "\e820";
}

.icon-up-thin:before {
  content: "\e821";
}

.icon-down-arrow:before {
  content: "\e822";
}

.icon-left-arrow:before {
  content: "\e823";
}

.icon-right-arrow:before {
  content: "\e824";
}

.icon-up-arrow:before {
  content: "\e825";
}

.icon-menu:before {
  content: "\e826";
}

.icon-home:before {
  content: "\e827";
}

.icon-lock:before {
  content: "\e828";
}

.icon-phone:before {
  content: "\e829";
}

.icon-chat:before {
  content: "\e830";
}

.icon-mail:before {
  content: "\e831";
}

.icon-comment:before {
  content: "\e832";
}

.icon-at:before {
  content: "\e833";
}

.icon-star:before {
  content: "\e834";
}

.icon-search:before {
  content: "\e835";
}

.icon-zoom:before {
  content: "\e836";
}

.icon-cog:before {
  content: "\e837";
}

.icon-link:before {
  content: "\e838";
}

.icon-share:before {
  content: "\e839";
}

.icon-check:before {
  content: "\e840";
}

.icon-cancel:before {
  content: "\e841";
}

.icon-plus:before {
  content: "\e842";
}

.icon-minus:before {
  content: "\e843";
}

.icon-close:before {
  content: "\e844";
}

.icon-user:before {
  content: "\e845";
}

.icon-spinner:before {
  content: "\e846";
}

.icon-youtube:before {
  content: "\e847";
}

a.social {
  display: inline-block;
  height: 50px;
  width: 50px;
  margin: 5px;
  color: #fff !important;
  background-color: #ccc;
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 22px;
  text-align: center;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 30px;
  transition: all 0.15s linear 0s;
}

a.social [class^=icon-]:before {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 50px;
}

a.social:hover,
a.social:active {
  font-size: 24px;
  border-radius: 4px;
  /*transform: rotate(360deg);*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a.social.dribbble:hover,
a.social.dribbble:active {
  background-color: #ea4c89;
}

a.social.instagram:hover,
a.social.instagram:active {
  background-color: #49789d;
}

a.social.behance:hover,
a.social.behance:active {
  background-color: #1769ff;
}

a.social.facebook:hover,
a.social.facebook:active {
  background-color: #3f5495;
}

a.social.twitter:hover,
a.social.twitter:active {
  background-color: #5d87c4;
}

a.social.linkedin:hover,
a.social.linkedin:active {
  background-color: #2575b2;
}

a.social.gplus:hover,
a.social.gplus:active {
  background-color: #cc4436;
}

a.social.pinterest:hover,
a.social.pinterest:active {
  background-color: #c2142f;
}

a.social.tumblr:hover,
a.social.tumblr:active {
  background-color: #529ecc;
}

a.social.youtube:hover,
a.social.youtube:active {
  background-color: #ff0000;
}

/* open-sans-300 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-300.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-300.woff") format("woff"), url("../fonts/open-sans-v18-latin-300.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-300.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-regular.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-regular.woff") format("woff"), url("../fonts/open-sans-v18-latin-regular.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-regular.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-italic.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-italic.woff") format("woff"), url("../fonts/open-sans-v18-latin-italic.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-600.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-600.woff") format("woff"), url("../fonts/open-sans-v18-latin-600.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-600.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-600italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-600italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-600italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-600italic.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-600italic.woff") format("woff"), url("../fonts/open-sans-v18-latin-600italic.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-600italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-700.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-700.woff") format("woff"), url("../fonts/open-sans-v18-latin-700.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-700.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-700italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-700italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-700italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-700italic.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-700italic.woff") format("woff"), url("../fonts/open-sans-v18-latin-700italic.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-700italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* fjalla-one-regular - latin */
@font-face {
  font-family: "Fjalla One";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/fjalla-one-v8-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/fjalla-one-v8-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/fjalla-one-v8-latin-regular.woff2") format("woff2"), url("../fonts/fjalla-one-v8-latin-regular.woff") format("woff"), url("../fonts/fjalla-one-v8-latin-regular.ttf") format("truetype"), url("../fonts/fjalla-one-v8-latin-regular.svg#FjallaOne") format("svg"); /* Legacy iOS */
}
/* roboto-slab-regular - latin */
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/roboto-slab-v13-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-slab-v13-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v13-latin-regular.woff2") format("woff2"), url("../fonts/roboto-slab-v13-latin-regular.woff") format("woff"), url("../fonts/roboto-slab-v13-latin-regular.ttf") format("truetype"), url("../fonts/roboto-slab-v13-latin-regular.svg#RobotoSlab") format("svg"); /* Legacy iOS */
}
/* roboto-slab-600 - latin */
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/roboto-slab-v13-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-slab-v13-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v13-latin-600.woff2") format("woff2"), url("../fonts/roboto-slab-v13-latin-600.woff") format("woff"), url("../fonts/roboto-slab-v13-latin-600.ttf") format("truetype"), url("../fonts/roboto-slab-v13-latin-600.svg#RobotoSlab") format("svg"); /* Legacy iOS */
}
/* roboto-slab-700 - latin */
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/roboto-slab-v13-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-slab-v13-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v13-latin-700.woff2") format("woff2"), url("../fonts/roboto-slab-v13-latin-700.woff") format("woff"), url("../fonts/roboto-slab-v13-latin-700.ttf") format("truetype"), url("../fonts/roboto-slab-v13-latin-700.svg#RobotoSlab") format("svg"); /* Legacy iOS */
}
/* montserrat-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/montserrat-v25-latin-300.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-300.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/montserrat-v25-latin-300italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-300italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/montserrat-v25-latin-regular.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-regular.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/montserrat-v25-latin-italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/montserrat-v25-latin-500.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-500.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/montserrat-v25-latin-500italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-500italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/montserrat-v25-latin-600.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-600.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/montserrat-v25-latin-600italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-600italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/montserrat-v25-latin-700.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-700.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/montserrat-v25-latin-700italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-700italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/montserrat-v25-latin-800.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-800.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* material icons */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/MaterialIcons-Regular.woff2") format("woff2");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-osx-font-smoothing: grayscale;
}

/* fallback */
@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/MaterialIconsOutlined-Regular.otf") format("woff2");
}
.material-icons-outlined {
  font-family: "Material Icons Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-osx-font-smoothing: grayscale;
}

/* fallback */
@font-face {
  font-family: "Material Icons Round";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/MaterialIconsRound-Regular.otf") format("woff2");
}
.material-icons-round {
  font-family: "Material Icons Round";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-osx-font-smoothing: grayscale;
}

/* ----------------------------------------------------------------------------
    CSS Variable, needs to be set in :root
----------------------------------------------------------------------------- */
:root {
  --font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans";
  --font-family-headline: "Montserrat", var(--font-family);
}

/* ----------------------------------------------------------------------------
    Smooth Scrolling
----------------------------------------------------------------------------- */
@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}
[id] {
  scroll-margin-top: 1rem;
}

/* ----------------------------------------------------------------------------
    Typography
----------------------------------------------------------------------------- */
body {
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 600;
  font-family: "Open Sans", var(--font-family);
  line-height: 1.4;
  color: #313131;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit !important;
}

a {
  color: #0d6efd;
  text-decoration: none;
  outline: none !important;
}

a:hover,
a:active {
  text-decoration: none;
  border-bottom-width: 1px;
  border-bottom-style: dotted;
  border-bottom-color: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #408cfd;
}

a.img:hover,
a.img:active {
  text-decoration: none;
  border: 0;
}

a[href^="tel:"] {
  color: inherit;
  border: 0;
}

ul.nav,
ol.nav {
  margin-left: 0;
  margin-top: 1em;
  margin-bottom: 1em;
}

.cfix:after,
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

#content,
#main_content,
.container,
.container-fluid,
.break_word {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

/* ----------------------------------------------------------------------------
    Forms Elements
----------------------------------------------------------------------------- */
.opacity-1 {
  opacity: 1 !important;
}

.display-none {
  display: none !important;
}

/* ----------------------------------------------------------------------------
    Buttons
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Tables
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Modals
----------------------------------------------------------------------------- */
.modal {
  backdrop-filter: blur(5px);
}

.modal-header {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.modal-header .close {
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  margin-top: -0.7rem;
}

.modal-body .btn-close {
  opacity: 1;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  text-align: center;
  z-index: 100;
  background-color: #ffffff;
  border-bottom-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

/* ----------------------------------------------------------------------------
  Dropdown List
----------------------------------------------------------------------------- */
.dropdown-list {
  margin: auto;
  width: auto;
  position: relative;
  box-sizing: border-box;
}

.dropdown-list ul.drop {
  margin: 0;
  padding: 0;
  list-style: none;
}

.dropdown-list li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.dropdown-list span.dropdown {
  display: block;
  margin: 0;
  padding: 0 10px;
  width: auto;
  height: 40px;
  line-height: 38px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #a1c4e5;
  cursor: pointer;
  overflow: hidden;
}

.dropdown-list span.dropdown.is-active {
  border-radius: 3px 3px 0 0;
}

.dropdown-list span.dropdown:hover,
.dropdown-list span.dropdown:active {
  background: #f1f1f1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.dropdown-list span.dropdown:after {
  float: right;
  margin-top: 0;
  margin-left: 10px;
  width: 15px;
  height: 100%;
  content: "\e817";
  color: ineherit;
  font-family: "icons";
  text-align: center;
  font-size: inherit;
  vertical-align: top;
}

.dropdown-list span.dropdown.is-active:after {
  content: "\e816";
}

.dropdown-list a:hover {
  text-decoration: none;
  border-bottom: 0;
}

.dropdown-list .drop {
  display: block;
  float: left;
  margin: 0;
  width: 100%;
  max-height: 0;
  position: absolute;
  background: #fff;
  top: 40px;
  border-radius: 0 0 3px 3px;
  z-index: 10;
  overflow-y: auto;
  opacity: 0;
  transition: max-height 0.3s cubic-bezier(0, 0.6, 0, 1), opacity 0.3s cubic-bezier(0, 0.6, 0, 1);
}

.dropdown-list span.is-active + .drop {
  max-height: 350px;
  border: 1px solid #a1c4e5;
  border-top: 0;
  opacity: 1;
  transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.dropdown-list .drop li {
  float: none;
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  height: auto;
  border-top: 1px dotted #ccc;
}

.dropdown-list .drop li:first-child {
  border-top: 0;
}

.dropdown-list .drop li a {
  float: none;
  display: block;
  margin: 0;
  padding: 6px 10px 6px 25px;
  height: auto;
  width: auto;
  text-transform: none;
  color: inherit;
  background: #fff;
  text-align: left;
  border-radius: 0;
  border: 0;
}

.dropdown-list .drop li a:hover,
.dropdown-list .drop li a:active {
  background: #f1f1f1;
}

.dropdown-list .drop li a:before {
  display: inline-block;
  margin-left: -15px;
  margin-right: 5px;
  width: 10px;
  height: inherit;
  content: "»";
  color: inherit;
  font-family: "icons";
  text-align: center;
  font-size: inherit;
  vertical-align: top;
}

ul,
ol {
  padding-left: 30px;
}

ul li,
ol li {
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

ul.check,
ul.star,
ul.carrot {
  margin-left: 0;
  padding-left: 0;
  list-style: none outside;
  line-height: inherit;
}

ul.check li,
ul.star li,
ul.carrot li {
  padding-left: 1.3em;
  margin-left: 0;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  position: relative;
}

ul.check li li,
ul.star li li,
ul.carrot li li {
  padding-left: 0;
}

ul.carrot li {
  padding-left: 0.9em;
}

ul.check > li:before,
ul.star > li:before,
ul.carrot > li:before {
  display: block;
  margin-top: 0.1em;
  width: 1em;
  height: inherit;
  color: inherit;
  font-size: 0.9em;
  font-weight: normal !important;
  word-wrap: normal;
  position: absolute;
  left: 0;
}

ul.check > li:before {
  content: "\e840";
  font-family: "icons";
}

ul.star > li:before {
  content: "\e834";
  font-family: "icons";
}

ul.carrot > li:before {
  content: "»";
  font-family: "icons";
  margin-top: 0.2em;
  font-size: 1em;
  font-weight: 600;
  line-height: 1;
  width: 1em;
}

/* ----------------------------------------------------------------------------
  Accordion
----------------------------------------------------------------------------- */
.accordion-wrap {
  margin-bottom: 1rem;
}
.accordion-wrap .accordion {
  padding: 0;
  border-bottom: 1px dotted #ccc;
  overflow: hidden;
}
.accordion-wrap .accordion:first-child {
  border-top: 1px dotted #ccc;
}
.accordion-wrap .accordion .accordion-toggle {
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: calc(1em + 10px);
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  overflow: hidden;
}
.accordion-wrap .accordion .accordion-toggle:before {
  float: right;
  color: #e32129;
  content: "\e817";
  font-family: "icons";
  font-size: 1em;
  font-weight: normal !important;
  height: inherit;
  width: 1em;
  margin-right: -1em;
  text-align: right;
}
.accordion-wrap .accordion .accordion-toggle:hover {
  background-color: white;
}
.accordion-wrap .accordion.is-open > .accordion-content {
  padding-top: 5px;
  opacity: 1;
  transform: scaleY(1);
}
.accordion-wrap .accordion.is-open > .accordion-toggle:before {
  content: "\e816";
}
.accordion-wrap .accordion .accordion-content {
  padding: 0 10px;
  max-height: 0;
  opacity: 0;
  transform-origin: top;
  transition: max-height 0.35s cubic-bezier(0.075, 0.82, 0.165, 1), opacity 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
  overflow: hidden;
}

/* ----------------------------------------------------------------------------
  Bootstrap Overrides
----------------------------------------------------------------------------- */
b,
strong {
  font-weight: 600;
}

.modal-header {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.modal-header h1,
.modal-header h2,
.modal-header h3,
.modal-header h4,
.modal-header h5 {
  margin-top: 0;
  font-weight: 400;
}

input[type=number] {
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::placeholder {
  color: #e0e0e0 !important;
}

.custom-control.is-invalid .custom-control-input ~ .custom-control-label, .custom-control:invalid .custom-control-input ~ .custom-control-label {
  color: #fb4f16;
}
.custom-control.is-invalid .custom-control-input ~ .custom-control-label:before, .custom-control:invalid .custom-control-input ~ .custom-control-label:before {
  border-color: #fb4f16;
}
.custom-control.is-invalid .custom-control-input:focus ~ .custom-control-label:before, .custom-control:invalid .custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.custom-control.custom-checkbox {
  padding-left: 1.75rem;
}
.custom-control.custom-checkbox .custom-control-label:before, .custom-control.custom-checkbox .custom-control-label:after {
  left: -1.75rem;
  width: 1.2rem;
  height: 1.2rem;
}
.custom-control.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  border-color: #316ce8;
  background-color: #316ce8;
}
.custom-control.custom-radio {
  padding-left: 1.75rem;
}
.custom-control.custom-radio .custom-control-label:before, .custom-control.custom-radio .custom-control-label:after {
  left: -1.75rem;
  width: 1.2rem;
  height: 1.2rem;
}
.custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
  border-color: #316ce8;
  background-color: #316ce8;
}

.form-control.is-invalid {
  border-color: #fb4f16;
}
.form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  background-image: none;
}

.custom-select.form-control-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.custom-select.is-invalid {
  border-color: #fb4f16;
}
.custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #80bdff;
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
}

.table {
  word-wrap: normal;
  word-break: normal;
}
.table.align-middle th,
.table.align-middle td {
  vertical-align: middle;
}
.table.table-sm td,
.table.table-sm th {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.list-group-item {
  margin-top: 0;
  margin-bottom: 0;
}

/* ----------------------------------------------------------------------------
  ASC Default stuff
----------------------------------------------------------------------------- */
.price {
  display: inline-block;
  padding: 0;
  width: auto;
  height: auto;
  color: #444;
  text-align: center;
  font: bold 0 "Trebuchet MS", "Droid Serif", Arial, sans-serif;
  overflow: visible;
  text-shadow: 0 0 40px #333;
  vertical-align: middle;
  color: transparent;
}

.price.static {
  color: #444;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
}

.price.shown {
  color: #444;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
  transition: color ease-in-out 0.1s, text-shadow ease-in-out 0.1s;
}

.price.ribbon {
  margin-top: -6px;
  margin-bottom: 0;
  padding: 28px 0 0 0;
  width: 140px;
  height: 93px;
  color: #fff;
  background: url(../images/price_bg.png) no-repeat 0 0;
  text-shadow: 0 0 25px #fff;
  color: transparent;
}

.price.burst {
  padding: 60px 0 0 0;
  width: 155px;
  height: 155px;
  color: #fff;
  background: url(../images/price_bg_burst.png) no-repeat 50% 50%;
  text-shadow: 0 0 25px #fff;
  color: transparent;
}

.price.ribbon.static,
.price.burst.static {
  color: #fff;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
}

.price.ribbon.shown,
.price.burst.shown {
  color: #fff;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
  transition: color ease-in-out 0.1s, text-shadow ease-in-out 0.1s;
}

.price span.dollar-sign,
.price span.cents,
.price span.cents-fees,
.price span.only {
  display: inline-block;
  margin: 5px 0 0 0;
  font-size: 30px;
  line-height: 30px;
  vertical-align: top;
}

.price span.only {
  margin-bottom: 5px;
  font-size: 26px;
  display: block;
}

.price span.dollars,
.price span.dollars-fees {
  display: inline-block;
  margin-left: -1px;
  font-size: 50px;
  line-height: 50px;
  letter-spacing: -1px;
  vertical-align: middle;
}

.price span.cents,
.price span.cents-fees {
  margin: 4px 0 0 3px;
  letter-spacing: -1px;
}

.price.shown span.reg-price {
  font-size: 14px;
  font-weight: 400;
  color: #777;
  text-decoration: line-through;
  padding-left: 3px;
  vertical-align: middle;
  text-shadow: none;
}

.price.ribbon span.reg-price {
  color: #eee;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
}

.price span.dollars[data-pid]:after,
.price span.dollars-fees[data-pid]:after,
.price span.cents[data-pid]:after,
.price span.cents-fees[data-pid]:after,
.price span.dollars[data-pkgid]:after,
.price span.cents[data-pkgid]:after {
  display: inline-block;
  content: "##";
}

.price.shown span.dollars[data-pid]:after,
.price.shown span.dollars-fees[data-pid]:after,
.price.shown span.cents[data-pid]:after,
.price.shown span.cents-fees[data-pid]:after,
.price.shown span.dollars[data-pkgid]:after,
.price.shown span.cents[data-pkgid]:after {
  display: none;
}

/* -- for dynamic LGAs -- */
.lga-item {
  display: none;
  opacity: 0;
}
.lga-item.lga-replaced {
  display: inline;
  opacity: 1;
}

/* -- Customize Bootstrap Utilities-- */
.w-auto {
  max-width: 100% !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

@media (min-width: 576px) {
  .w-sm-auto {
    width: 100% !important;
  }
  .w-sm-5 {
    width: 5% !important;
  }
  .w-sm-10 {
    width: 10% !important;
  }
  .w-sm-20 {
    width: 20% !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-30 {
    width: 30% !important;
  }
  .w-sm-40 {
    width: 40% !important;
  }
  .w-sm-45 {
    width: 45% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-60 {
    width: 60% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-80 {
    width: 80% !important;
  }
  .w-sm-90 {
    width: 90% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
}
@media (min-width: 768px) {
  .w-md-auto {
    width: 100% !important;
  }
  .w-md-5 {
    width: 5% !important;
  }
  .w-md-10 {
    width: 10% !important;
  }
  .w-md-20 {
    width: 20% !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-30 {
    width: 30% !important;
  }
  .w-md-40 {
    width: 40% !important;
  }
  .w-md-45 {
    width: 45% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-80 {
    width: 80% !important;
  }
  .w-md-90 {
    width: 90% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
}
@media (min-width: 992px) {
  .w-lg-auto {
    width: 100% !important;
  }
  .w-lg-5 {
    width: 5% !important;
  }
  .w-lg-10 {
    width: 10% !important;
  }
  .w-lg-20 {
    width: 20% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .w-lg-45 {
    width: 45% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-80 {
    width: 80% !important;
  }
  .w-lg-90 {
    width: 90% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
}
@media (min-width: 1200px) {
  .w-xl-auto {
    width: 100% !important;
  }
  .w-xl-5 {
    width: 5% !important;
  }
  .w-xl-10 {
    width: 10% !important;
  }
  .w-xl-20 {
    width: 20% !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-30 {
    width: 30% !important;
  }
  .w-xl-40 {
    width: 40% !important;
  }
  .w-xl-45 {
    width: 45% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-60 {
    width: 60% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-80 {
    width: 80% !important;
  }
  .w-xl-90 {
    width: 90% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
}
@media (min-width: 1400px) {
  .w-xxl-auto {
    width: 100% !important;
  }
  .w-xxl-5 {
    width: 5% !important;
  }
  .w-xxl-10 {
    width: 10% !important;
  }
  .w-xxl-20 {
    width: 20% !important;
  }
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-30 {
    width: 30% !important;
  }
  .w-xxl-40 {
    width: 40% !important;
  }
  .w-xxl-45 {
    width: 45% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-60 {
    width: 60% !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .w-xxl-80 {
    width: 80% !important;
  }
  .w-xxl-90 {
    width: 90% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
}
.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.ls-10 {
  letter-spacing: 0.1rem;
}

.ls-15 {
  letter-spacing: 0.15rem;
}

.ls-20 {
  letter-spacing: 0.2rem;
}

.ls-25 {
  letter-spacing: 0.25rem;
}

.lh-11 {
  line-height: 1.1;
}

.lh-12 {
  line-height: 1.2;
}

.lh-13 {
  line-height: 1.3;
}

.lh-14 {
  line-height: 1.4;
}

.lh-16 {
  line-height: 1.6;
}

.lh-20 {
  line-height: 2;
}

.lh-25 {
  line-height: 2.5;
}

/* ----------------------------------------------------------------------------
  Navigation Menu
----------------------------------------------------------------------------- */
#nav-wrap {
  background: #e32129;
}

#menutoggle {
  display: block;
  margin: 0;
  padding: 0 15px;
  width: auto;
  color: #e32129;
  background: transparent;
  height: 50px;
  font-size: 1.1rem;
  font-family: "Montserrat", var(--font-family);
  font-weight: 600;
  line-height: 46px;
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  right: 0;
  border-radius: 5px;
  border: 0;
  transition: background 0.2s linear 0s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
#menutoggle span {
  display: none;
  margin-left: 10px;
  vertical-align: middle;
}
#menutoggle .hamburger-box {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 24px;
  vertical-align: middle;
}
#menutoggle .hamburger-box .hamburger-inner {
  display: block;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 75ms;
}
#menutoggle .hamburger-box .hamburger-inner,
#menutoggle .hamburger-box .hamburger-inner::after,
#menutoggle .hamburger-box .hamburger-inner::before {
  position: absolute;
  width: 30px;
  height: 3px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform, width;
  border-radius: 4px;
  background-color: #e32129;
  right: 0;
}
#menutoggle .hamburger-box .hamburger-inner {
  top: 0;
  width: 25px;
}
#menutoggle .hamburger-box .hamburger-inner::after {
  width: 22px;
}
#menutoggle .hamburger-box .hamburger-inner::after,
#menutoggle .hamburger-box .hamburger-inner::before {
  display: block;
  content: "";
}
#menutoggle .hamburger-box .hamburger-inner::before {
  top: 10px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform, opacity;
}
#menutoggle .hamburger-box .hamburger-inner::after {
  top: 20px;
}
#menutoggle.is-active .hamburger-inner,
#menutoggle.is-active .hamburger-inner::after,
#menutoggle.is-active .hamburger-inner::before {
  width: 30px;
}
#menutoggle.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
#menutoggle.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
}
#menutoggle.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-90deg);
}
#menutoggle:hover {
  background-color: #fdeded;
}
#menutoggle:hover .hamburger-box .hamburger-inner,
#menutoggle:hover .hamburger-box .hamburger-inner::after,
#menutoggle:hover .hamburger-box .hamburger-inner::before {
  width: 30px;
}
#menutoggle.is-active + #nav ul {
  padding-top: 5px;
  max-height: 1000px;
  opacity: 1;
  transition: max-height 0.3s ease-in-out, opacity 0.25s ease-in-out, padding-top 0.25s ease-in-out;
}

#nav {
  clear: both;
  margin: 0;
  width: 100%;
  height: 0;
  font-family: "Montserrat", var(--font-family);
  overflow: visible;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1000;
}
#nav ul {
  float: none;
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  opacity: 0;
  max-height: 0;
  background: #fff;
  overflow: hidden;
  transition: max-height 0.25s cubic-bezier(0, 0.7, 0, 1), opacity 0.2s ease-in-out, padding-top 0.25s ease-in-out;
  border-radius: 0;
  bottom: 0;
  right: 0;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  position: relative;
}
#nav ul.submenu {
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 0;
  background: none;
  opacity: 1;
  position: relative;
  top: 0;
  transition: max-height 0.15s linear 0s;
}
#nav ul.submenu a {
  padding-left: 40px;
  background: none;
}
#nav ul.submenu a:before {
  display: inline-block;
  margin-right: 5px;
  margin-left: -15px;
  width: 10px;
  height: inherit;
  content: "»";
  color: inherit;
  font-family: "icons";
  text-align: center;
  font-size: inherit;
  vertical-align: top;
}
#nav li {
  display: block;
  margin: 0;
  padding: 0;
  height: auto;
  overflow: hidden;
  border: 0;
  border-top: 1px dotted #7e7e7e;
}
#nav li.onmobile {
  display: block;
}
#nav a,
#nav span.submenu {
  float: none;
  display: block;
  margin: 0;
  padding: 9px 20px;
  height: auto;
  color: #e32129;
  line-height: 1.6em;
  text-align: left;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  border: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: background 0.17s linear 0s;
}
#nav span.submenu:after,
#nav a.submenu:after {
  float: right;
  margin-left: 10px;
  width: 15px;
  height: inherit;
  content: "\e817";
  color: inherit;
  font-family: "icons";
  text-align: center;
  font-size: 105%;
  vertical-align: top;
}
#nav span.submenu.is-active,
#nav a.submenu.is-active {
  border-bottom: 1px dotted #3586fd;
}
#nav span.submenu.is-active + ul.submenu,
#nav a.submenu.is-active + ul.submenu {
  max-height: 360px;
  overflow-y: auto;
}
#nav span.submenu.is-active:after,
#nav a.submenu.is-active:after {
  content: "\e816";
}
#nav a:hover,
#nav a.is-active,
#nav li:hover span.submenu,
#nav li:hover a.submenu,
#nav ul.submenu a:hover,
#nav ul.submenu a.is-active {
  background: #e32129;
  color: #fff;
}

@media (min-width: 36em) {
  /* Small devices (landscape phones, 576px and up) */
  /*@media (min-width: 48em) { /* Medium devices (tablets, 768px and up) */
  #menutoggle {
    padding: 0 15px 0 15px;
  }
  #menutoggle span {
    display: inline-block;
  }
  #menutoggle:before {
    margin-right: 10px;
  }
}
@media (min-width: 48em) {
  /* Medium devices (tablets, 768px and up) */
  #nav ul {
    width: calc(100% + 36px);
    left: -18px;
  }
  #nav a,
  #nav span.submenu {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 992px) {
  /* Large devices (desktops, 992px and up) */
  #menutoggle {
    display: none;
  }
  #nav {
    width: auto;
    height: 45px;
    background: transparent;
    position: relative;
    z-index: 99;
    border: 0;
    border-radius: 4px;
  }
  #nav ul {
    display: block !important;
    float: left;
    width: 100%;
    height: auto;
    opacity: 1;
    margin: 4px 0;
    padding-top: 0;
    position: relative;
    z-index: 10;
    top: 0;
    left: 0;
    overflow: visible;
    box-shadow: none;
  }
  #nav ul.submenu {
    float: left;
    margin: 0;
    padding: 0;
    width: 250px;
    max-height: 0;
    position: absolute;
    opacity: 0;
    background: #e7434a;
    top: 95%;
    z-index: 10;
    border-radius: 0 3px 3px 3px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    transition: max-height 0.2s linear 0s, padding 0.2s linear 0s, opacity 0.2s linear 0s;
    overflow: hidden;
    overflow-y: auto;
  }
  #nav ul.submenu li {
    float: none;
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    height: auto;
  }
  #nav ul.submenu li:first-child a {
    border: 0;
  }
  #nav ul.submenu a {
    float: none;
    display: block;
    margin: 0;
    padding: 7px 15px 7px 28px;
    height: auto;
    text-transform: none;
    text-align: left;
    line-height: 1.5em;
    border-radius: 0;
    border: 0;
    border-top: 1px dotted #e32129;
  }
  #nav li {
    float: left;
    margin: 0 10px;
    padding: 0;
    height: 37px;
    width: auto;
    min-width: auto;
    position: relative;
    border: 0;
    overflow: visible;
  }
  #nav li:first-child {
    margin-left: 0;
    border-left: 0;
  }
  #nav li:last-child {
    margin-right: 0;
  }
  #nav li.last {
    border-right: 0;
  }
  #nav li.right {
    float: right;
    margin-right: 0;
    border-right: 0;
  }
  #nav li.onmobile {
    display: none;
  }
  #nav li:hover {
    z-index: 11;
  }
  #nav a,
  #nav span.submenu {
    float: left;
    margin: 0;
    padding: 0 2px;
    height: 37px;
    border: 0;
    color: #313131;
    text-shadow: none;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 39px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 3px;
    transition: background 0.2s ease-in-out 0s, border 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s;
    cursor: pointer;
    border-radius: 0;
    position: relative;
  }
  #nav a::after,
  #nav span.submenu::after {
    bottom: 0;
    left: 50%;
    position: absolute;
    content: "";
    height: 2px;
    width: 0;
    background-color: #e32129;
    transition: left 0.2s ease-in-out 0s, width 0.2s ease-in-out 0s;
  }
  #nav a.purchase {
    padding: 0 14px;
    border: 2px solid #313131;
    border-radius: 50px;
    line-height: 35px;
    text-transform: uppercase;
  }
  #nav a.purchase::after {
    display: none;
  }
  #nav a.purchase:hover {
    color: #fff;
    background-color: #e32129;
    border-color: #e32129;
  }
  #nav span.submenu:after,
  #nav a.submenu:after {
    display: none;
  }
  #nav a:hover,
  #nav a.is-active,
  #nav li:hover span.submenu,
  #nav li:hover a.submenu,
  #nav li:hover a.submenu,
  #nav ul.submenu a:hover,
  #nav span.submenu.is-active,
  #nav a.submenu.is-active {
    background: transparent;
    position: relative;
    color: #313131;
  }
  #nav a:hover::after,
  #nav a.is-active::after,
  #nav li:hover span.submenu::after,
  #nav li:hover a.submenu::after,
  #nav li:hover a.submenu::after,
  #nav ul.submenu a:hover::after,
  #nav span.submenu.is-active::after,
  #nav a.submenu.is-active::after {
    position: absolute;
    content: "";
    left: 0;
    height: 2px;
    width: 100%;
  }
  #nav li:hover a.submenu,
  #nav li:hover span.submenu,
  #nav li:hover a.submenu,
  #nav span.submenu.is-active.hover,
  #nav a.submenu.is-active.hover {
    border-radius: 3px 3px 0 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  #nav li:hover ul.submenu,
  #nav ul.submenu.is-active {
    padding: 3px 0;
    left: 0;
    max-height: 360px;
    opacity: 1;
  }
  #nav ul.submenu li a:hover,
  #nav ul.submenu li a.is-active {
    background: #e32129;
  }
}
/* ----------------------------------------------------------------------------
  Buttons
----------------------------------------------------------------------------- */
.cta-btn {
  display: inline-block;
  padding: 10px 0 8px 0;
  color: #fff;
  background-color: #555555;
  text-align: center;
  position: relative;
  width: 100%;
  max-width: 220px;
  font-size: 0.8rem;
  font-weight: 600;
  font-family: "Montserrat", var(--font-family);
  text-transform: uppercase;
  border-radius: 4px;
  cursor: pointer;
  vertical-align: middle;
  outline: 0;
  overflow: hidden;
  transition: color 0.15s ease-in-out 0.03s, background-color 0.15s ease-in-out 0.03s, box-shadow 0.15s ease-in-out 0.03s;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.cta-btn.wide {
  max-width: 270px;
}
.cta-btn:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #3c3c3c 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.3s, opacity 0.5s;
}
.cta-btn:active {
  transform: translateY(1px);
}
.cta-btn:active:after {
  transform: scale(0, 0);
  opacity: 0.2;
  transition: 0s;
}

.cta-btn.fancy {
  background: #555555;
  background-image: linear-gradient(dimgray, #3c3c3c);
}

.cta-btn:hover,
.cta-btn:active {
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: #434343;
  border: 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.cta-btn.big {
  padding: 13px 0 10px 0;
}

.cta-btn.big.outline {
  padding: 12px 0 9px 0;
}

.cta-btn.small {
  padding: 6px 0 6px 0;
  font-size: 1rem;
}

.cta-btn.small.fancy {
  line-height: 41px;
}

.cta-btn.small.outline {
  padding: 6px 0 5px 0;
}

.cta-btn.dark {
  color: #fff;
  background: #555555;
}

.cta-btn.white {
  color: #333;
  background: #fff;
}

.cta-btn.orange {
  color: #fff;
  background: #ff6633;
}

.cta-btn.yellow {
  color: #333;
  background: #ffc107;
}

.cta-btn.green {
  color: #fff;
  background: #0d6efd;
}

.cta-btn.red {
  color: #fff;
  background: #e32129;
}

.cta-btn.blue {
  color: #fff;
  background: #0d6efd;
}

.cta-btn[class~=outline] {
  border: 1px solid #555555;
}

.cta-btn.outline {
  color: #555555;
  background: transparent;
  border-color: #555555;
  border-width: 2px;
}

.cta-btn.dark.outline {
  color: #555555;
  background: transparent;
  border-color: #555555;
}

.cta-btn.white.outline {
  color: #fff;
  background: transparent;
  border-color: #fff;
}

.cta-btn.orange.outline {
  color: #ff6633;
  background: transparent;
  border-color: #ff6633;
}

.cta-btn.green.outline {
  color: #0d6efd;
  background: transparent;
  border-color: #0d6efd;
}

.cta-btn.red.outline {
  color: #e32129;
  background: transparent;
  border-color: #e32129;
}

.cta-btn.yellow.outline {
  color: #ffc107;
  background: transparent;
  border-color: #ffc107;
}

.cta-btn.blue.outline {
  color: #0d6efd;
  background: transparent;
  border-color: #0d6efd;
}

.cta-btn.pill {
  border-radius: 100px;
}

.cta-btn.dark.fancy {
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  background: #555555;
  background-image: linear-gradient(dimgray, #3c3c3c);
}

.cta-btn.dark:hover,
.cta-btn.dark:active {
  background: #434343;
}

.cta-btn.outline:hover,
.cta-btn.outline:active,
.cta-btn.dark.outline:hover,
.cta-btn.dark.outline:active {
  background: rgba(85, 85, 85, 0.1);
}

.cta-btn.white:hover,
.cta-btn.white:active {
  background: #ededed;
}

.cta-btn.white.outline:hover,
.cta-btn.white.outline:active {
  color: #333;
  background: #fff;
}

.cta-btn.orange.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  background: #ff6633;
  background-image: linear-gradient(#ff855c, #ff531a);
}

.cta-btn.orange:hover,
.cta-btn.orange:active {
  background: #ff4b0f;
}

.cta-btn.orange.outline:hover,
.cta-btn.orange.outline:active {
  background: rgba(255, 102, 51, 0.1);
}

.cta-btn.green.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #0d6efd;
  background-image: linear-gradient(#3586fd, #0262ef);
  color: #fff;
}

.cta-btn.green:hover,
.cta-btn.green:active {
  background: #025de4;
}

.cta-btn.green.outline:hover,
.cta-btn.green.outline:active {
  background: #0d6efd;
  color: #fff;
}

.cta-btn.red.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #e32129;
  background-image: linear-gradient(#e8454c, #d01a22);
}

.cta-btn.red:hover,
.cta-btn.red:active {
  background: #c71920;
}

.cta-btn.red.outline:hover,
.cta-btn.red.outline:active {
  background: rgba(227, 33, 41, 0.1);
}

.cta-btn.blue.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #0d6efd;
  background-image: linear-gradient(#3586fd, #0262ef);
}

.cta-btn.blue:hover,
.cta-btn.blue:active {
  background: #3083fd;
}

.cta-btn.blue.outline:hover,
.cta-btn.blue.outline:active {
  background: #0d6efd;
  color: #fff;
}

.cta-btn.yellow.fancy {
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  background: #ffc107;
  background-image: linear-gradient(#ffce3a, #edb100);
}

.cta-btn.yellow:hover,
.cta-btn.yellow:active {
  background: #ffca2b;
}

.cta-btn.yellow.outline:hover,
.cta-btn.yellow.outline:active {
  background: rgba(255, 193, 7, 0.1);
}

/* ----------------------------------------------------------------------------
  Certus Buttons
----------------------------------------------------------------------------- */
.scroll-anim {
  opacity: 0;
  position: relative;
}

.fade-in {
  animation: fadeInAnimation cubic-bezier(0.215, 0.61, 0.355, 1) 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.fade-in-quick {
  animation: fadeInAnimation cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade-out-quick {
  animation: fadeOutAnimation cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.slide-in-top {
  animation: slideInTopAnimation cubic-bezier(0.215, 0.61, 0.355, 1) 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes slideInTopAnimation {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  75% {
    transform: translateY(0);
  }
  100% {
    opacity: 1;
  }
}
.slide-in-bottom {
  animation: slideInBottomAnimation cubic-bezier(0.215, 0.61, 0.355, 1) 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes slideInBottomAnimation {
  0% {
    opacity: 0;
    transform: translateY(200%);
  }
  75% {
    transform: translateY(0);
  }
  100% {
    opacity: 1;
  }
}
.slide-in-left {
  animation: slideInLeftAnimation cubic-bezier(0.215, 0.61, 0.355, 1) 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes slideInLeftAnimation {
  0% {
    opacity: 0;
    transform: translateX(-75%);
  }
  75% {
    transform: translateX(0);
  }
  100% {
    opacity: 1;
  }
}
.slide-in-right {
  animation: slideInRightAnimation cubic-bezier(0.215, 0.61, 0.355, 1) 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes slideInRightAnimation {
  0% {
    opacity: 0;
    transform: translateX(200%);
  }
  75% {
    transform: translateX(0);
  }
  100% {
    opacity: 1;
  }
}
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  color: #333333;
}

body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  background-color: #fff;
  color: #333333;
  overflow-x: hidden;
}

.wrap {
  padding: 30px 0;
  overflow-x: clip;
}

.container-fluid.but-fixed {
  max-width: 1356px;
}
.container-fluid.wider {
  max-width: 1800px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.border-transparent {
  border-color: rgba(255, 255, 255, 0.6117647059) !important;
}

.fw-strong {
  font-weight: 600 !important;
}

.parallax-overlay {
  height: calc(100% + 30px);
  top: -30px;
}

.zindex {
  z-index: 100;
}

/* --------- asc styles --------- */
#header-wrap {
  background-color: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

#header a.logo {
  border: 0;
  width: 100%;
  max-width: 170px;
  min-width: 170px;
}
#header a.logo img {
  width: auto;
  height: auto;
}
#header #support p {
  margin: 2px 12px 0 0;
}
#header #support p strong {
  font-size: 16px;
}
#header #support a.login {
  padding: 0 12px 2px 12px;
  color: #fff;
  background: #e32129;
  text-decoration: none;
  border-radius: 0 0 4px 4px;
  border: 0;
}
#header #support a.login:hover {
  background: #e7434a;
}
#header #support .phone a:before {
  display: inline-block;
  margin-right: 5px;
  width: inherit;
  height: inherit;
  content: "\e829";
  color: inherit;
  font-family: "icons";
  font-weight: normal;
  text-align: center;
  font-size: 105%;
  vertical-align: top;
}
#header #search {
  position: absolute;
  right: -115px;
  top: 48px;
  display: none;
}
#header #search input[type=search] {
  width: 250px;
  transition: all 0.5s cubic-bezier(0, 0.7, 0, 1);
  padding-left: 35px;
  background-color: #f4f4f4;
  background-position: 9px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}
#header #search input[type=search]::placeholder {
  color: #999 !important;
}

.addsearch {
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'%3E%3Cg fill='none' stroke='gray' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2'%3E%3Cpath d='M10 3a7 7 0 107 7 7 7 0 00-7-7zM21 21l-6-6' vector-effect='non-scaling-stroke'%3E%3C/path%3E%3C/g%3E%3C/svg%3E") no-repeat 0px center;
}
.addsearch.form-control {
  background-color: #f2f2f2;
}
.addsearch.main {
  background-position: 9px;
  padding-left: 35px;
}
.addsearch.nav-search-icon {
  height: 37px;
  width: 20px;
  cursor: pointer;
}

.contact-icons .phone:before {
  margin-right: 5px;
  content: "\e829";
  color: inherit;
  font-family: "icons";
  font-size: 105%;
}
.contact-icons .email:before {
  margin-right: 5px;
  content: "\e831";
  color: inherit;
  font-family: "icons";
  font-size: 105%;
  position: relative;
  top: 2px;
}

#footer-wrap {
  background-color: #e32129;
}

.footer {
  width: 100%;
  background-color: transparent;
  color: #fff;
  font-size: 1rem;
}
.footer p.trustmark {
  display: none;
  margin: 0;
}
.footer a {
  color: inherit;
}
.footer .social a {
  border: 0;
}
.footer img {
  display: inline-block;
}
.footer #trustwaveSealImage {
  margin: 0 10px 30px 0;
}
.footer ul.links {
  padding-left: 0;
  list-style: none;
}
.footer ul.links li {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.footer a.social {
  height: 40px;
  width: 40px;
  margin: 0 5px;
  color: #e32129 !important;
  background-color: #fff;
  border: 2px solid #fff;
  font-size: 19px;
}
.footer a.social span {
  position: relative;
  top: -2px;
}
.footer a.social [class^=icon-]:before {
  line-height: 41px;
}
.footer a.social:hover,
.footer a.social:active {
  font-size: 21px;
  background: #e32129;
  color: #fff !important;
}

/* --------------------------------------------------------------
    Patterned BGs
-------------------------------------------------------------- */
.bg-slash-outline {
  background-image: url(/images/slash-outline.png);
  background-repeat: repeat;
  background-position: center center;
}

.bg-squares {
  background-image: url(/images/bg-squares-1.png);
  background-color: #fafafa;
  background-repeat: no-repeat;
  background-position: 100% 50%;
}
.bg-squares.one {
  background-image: url(/images/bg-squares-1.png);
}
.bg-squares.two {
  background-image: url(/images/bg-squares-2.png);
}
.bg-squares.three {
  background-image: url(/images/bg-squares-3.png);
}
.bg-squares.four {
  background-image: url(/images/bg-squares-4.png);
}

.border-light-blue {
  border-color: #f0f7fb !important;
}

.gradient-bg {
  background: linear-gradient(56deg, #293238, #47565d 20%, #37444b 78%, #283133);
}
.gradient-bg p {
  text-shadow: 1px 1px 15px rgba(78, 78, 78, 0.9019607843);
}

.zoom-container img.zoom {
  transition: transform 0.2s;
}
.zoom-container:hover img.zoom {
  transform: scale(1.05);
}

.side-socials a.social {
  height: 40px;
  width: 40px;
  margin: 0 5px;
  color: #fff;
  background-color: #313131;
  border: 2px solid #313131;
  font-size: 19px;
}
.side-socials a.social span {
  position: relative;
  top: -2px;
}
.side-socials a.social [class^=icon-]:before {
  line-height: 41px;
}
.side-socials a.social:hover,
.side-socials a.social:active {
  color: #313131 !important;
  background: #fff;
}

.featured-training svg path {
  fill: #bac8db;
  transition: 0.5s;
}
.featured-training:hover svg path {
  fill: #e32129;
}

#icon {
  min-width: 120px;
}

#banner-wrap {
  z-index: 1;
}
#banner-wrap.home img {
  width: 300px;
}

#reviews-carousel svg {
  position: absolute;
  left: 28px;
  top: -60px;
}
#reviews-carousel .tns-controls button {
  position: absolute;
  top: calc(50% - 20px);
  background: #e9e9e9;
  border: 3px solid #fff;
  color: #999;
  transition: 0.3s;
  line-height: 40px;
}
#reviews-carousel .tns-controls button:hover {
  background: #e32129;
  color: #fff;
}
#reviews-carousel .tns-controls button[data-controls=prev] {
  left: -30px;
}
#reviews-carousel .tns-controls button[data-controls=next] {
  right: -30px;
}
#reviews-carousel .tns-nav {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -50px;
}
#reviews-carousel .tns-nav button {
  transition: 0.3s;
}
#reviews-carousel .tns-nav button.tns-nav-active {
  transform: scale(1.6);
}

.learn-more-arrow {
  color: #0d6efd;
}
.learn-more-arrow:before {
  margin-right: 5px;
  content: "\e824";
  font-family: "icons";
  font-weight: normal;
}

.rectangle-formation-one .grey-rectangle {
  right: 27px;
  top: 1%;
}
.rectangle-formation-one .red-rectangle {
  right: 145px;
  top: 40%;
}
.rectangle-formation-one .blue-rectangle {
  right: 40px;
  top: 106px;
}
.rectangle-formation-one .blue-grey-rectangle {
  right: 160px;
  top: 5%;
}

.rectangle-formation-two .grey-rectangle {
  right: 27px;
  top: 1%;
}
.rectangle-formation-two .red-rectangle {
  right: 40px;
  top: 96px;
}
.rectangle-formation-two .blue-rectangle {
  right: 125px;
  top: 5%;
}
.rectangle-formation-two .blue-grey-rectangle {
  right: 173px;
  top: 105px;
}

.grey-rectangle {
  width: 120px;
  height: 120px;
  background-color: #f5f5f5;
  border-radius: 20px;
}
.grey-rectangle.dark {
  background-color: #ebebeb;
}
.grey-rectangle.small {
  width: 80px;
  height: 80px;
}
.grey-rectangle.right {
  right: 0px;
  top: 10%;
}
.grey-rectangle.left {
  left: -140px;
  top: 10%;
}
.grey-rectangle.mobile.left {
  left: -25px;
  top: 18px;
}
.grey-rectangle.mobile.right {
  right: -16px;
  top: 30px;
}

.red-rectangle {
  width: 170px;
  height: 170px;
  background-color: rgba(222, 39, 40, 0.5803921569);
  border-radius: 20px;
}
.red-rectangle.small {
  width: 100px;
  height: 100px;
}
.red-rectangle.light {
  background-color: #f1babb;
}
.red-rectangle.right {
  right: -100px;
  top: 40%;
}
.red-rectangle.left {
  left: -220px;
  top: 50%;
}
.red-rectangle.mobile.left {
  left: -105px;
  top: 88px;
}
.red-rectangle.mobile.right {
  right: -114px;
  top: 70px;
}

.blue-rectangle {
  width: 70px;
  height: 70px;
  background-color: #cce1ff;
  border-radius: 15px;
}

.blue-grey-rectangle {
  width: 50px;
  height: 50px;
  background-color: #dde3e9;
  border-radius: 10px;
}

.card {
  border: 1px solid #a1c4e5;
}

.subheading-light {
  color: #555555;
}

.roboto {
  font-family: "Roboto Slab", var(--font-family);
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
@media (min-width: 576px) {
  .bg-squares {
    background-position: 50% 50%;
  }
}
@media (min-width: 768px) {
  [class*=container-] {
    padding-right: var(--bs-gutter-x, 1.5rem);
    padding-left: var(--bs-gutter-x, 1.5rem);
  }
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header-wrap {
    border-top: 3px solid #e32129;
  }
  .bg-squares {
    background-position: 60% 50%;
  }
  #banner-wrap.home img {
    width: initial;
  }
}
@media (min-width: 992px) {
  [class*=container-] {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
  }
  #reviews-carousel svg {
    left: 75px;
  }
  .bg-squares {
    background-position: 100% 50%;
    right: -270px;
  }
}
@media (min-width: 1200px) {
  #banner h1 {
    font-size: 2.9rem;
    line-height: 1.2em;
  }
  .bg-squares {
    max-width: 1356px;
    right: -60px;
  }
}